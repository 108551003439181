@import "../src/assets/css/style.scss";
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
.cursor-pointer{
  cursor: pointer;
}
.custom-backdrop {
  background: rgba(0, 0, 0, 0.5) !important; /* Semi-transparent black background */
  backdrop-filter: blur(10px); /* Apply blur effect */
  -webkit-backdrop-filter: blur(10px); /* For Safari */
}
.cdk-overlay-pane.mat-mdc-dialog-panel{
  max-width: 100% !important;
}
