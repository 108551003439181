$color-black:#081F2B;
$color-black-text:#314149;
$color-blue:#5284E0;
$color-light-blue:#DAE5FB;
$color-white:#ffffff;
$color-gray:#485F6B;

$color-green:#00C48A;
$color-orange:#FFBB33;
$color-red:#FF2929;


.color-black{
    color:$color-black !important;
}
.color-white{
    color:$color-white !important;
}
.color-blue{
    color:$color-blue !important;
}
.text-light{
    color:#485F6B !important;
}

.color-green{
    color:$color-green !important;
}
.color-orange{
    color:$color-orange !important;
}
.color-red{
    color:$color-red !important;
}
.bg-green{
    background:$color-green !important;
}
.bg-orange{
    background:$color-orange !important;
}
.bg-red{
    background:$color-red !important;
}

