// import All variables 
@import "../css/variable/variable";
@import "ag-grid-community/styles/ag-grid.css";
@import "ag-grid-community/styles/ag-theme-quartz.css";

.main-content-wrapper-body {
    padding: 30px 40px 50px;
    position: relative;
    background-color: #fff;
}

.slide-navbar-wrap {
    background: #f5f8ff !important;
    z-index: 999;
    color: #667599;
    width: 250px !important;
    border-right: 1px solid #DFE3EC;
    visibility: visible;
    transform: none;
    padding: 110px 30px 0px 30px;
    position: fixed !important;
    top: 0px !important;
    left: 0px !important;

    .quick-access-link {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        a {
            margin: 0 0 25px 0;
            display: block;
            color: #667599;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            display: flex;
            align-items: center;
            gap: 10px;

            &:hover,.active {
                color: $color-black;
                img {
                    opacity: 1;
                }
            }
            &.active {
                color: $color-black;
                img {
                    opacity: 1;
                }
            }
        }
    }
}

.mat-sidenav-content.active {
    .main-content-wrapper-body {
        .header-title-sec,footer {
            margin-left: 250px;
            transition: 0.3s;
        }

    }
}
.hambarger-btn{
    margin-left: 0px;
    z-index: 9999999999999;
    position: absolute;
    bottom: 36px;
    left: 0px;
    border: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000000;
    background-color: #f5f8ff;
    padding: 5px;
}
.footer .contact_us a{
  color: #ffffff !important;
  font-weight: 400 !important;
}
.ag-theme-quartz {
    --ag-odd-row-background-color: rgb(0, 0, 0, 0.03);
    padding: 0px !important;
}
.ag-paging-page-size{
    border-radius: 1px soild black !important;
}

.ag-tooltip {
    white-space: pre-line;  
    background-color: #ffffff; 
    color: #021A53;            
    padding: 10px;              
    border-radius: 5px;         
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);  
    font-size: 14px;            
    line-height: 2.8;           
    max-width: 300px;           
    word-wrap: break-word;   
    display: inline-block; 
}
  
mat-dialog-container {
    &.mat-mdc-dialog-container {
      border-radius: 16px;
      min-width: 250px;
      overflow: unset;
      .mat-mdc-dialog-surface {
        border-radius: 16px;
        .custom_modal {
          .modal-content {
            .modal_title {
              background: #021A53 url(../images/header-bg.jpg) no-repeat center;
              background-size: cover;
              padding: 18px 30px;
              margin: 0;
              display: flex;
              justify-content: space-between;
              align-items: center;
              gap: 0 15px;
              h2 {
                margin-bottom: 0;
                color: #ffffff;
                font-size: 20px;
                font-weight: 600;
                line-height: 23px;
              }
  
              .btn-close {
                color: #000000;
                width: 20px;
                height: 20px;
                padding: 0;
                margin-right: 0;
                font-weight: 700;
                font-size: 20px;
                opacity: 1;
              }
  
              &::before {
                display: none;
              }
            }
  
            .modal_body {
              padding: 25px 30px;
              margin: 0;
              max-height: 615px;
              overflow-y: auto;
              max-width: 99%;  
  
              .form_field_wrapper {
                label {
                  font-weight: 400;
                  font-size: 12px;
                }
              }
  
              &.mat-mdc-dialog-content {
                &::-webkit-scrollbar {
                  width: 7px;
                }
  
                &::-webkit-scrollbar-thumb {
                  background-color: #dddddd;
                  border-radius: 5px;
                }
              }
            }
  
            .modal_footer {
              border-top: none;
              flex-wrap: nowrap;
              display: flex;
              padding: 10px 30px 25px;
              gap: 10px 15px;
              margin-bottom: 0;
              justify-content: flex-end;
            }
          }
        }
      }
    }
  }
  