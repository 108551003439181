.sec-quote-compare-bg{
	// margin: 0 0 35px 0;
	padding: 24px 24px 0 24px;
	border-radius: 16px;
	border: 1px solid #DFE3EC;
	background: #F5F8FF;
	font-weight: 400;
	height: 100%;
	.content-badge{
		margin: 0 0 12px 0;
	}
	a{
		padding: 10px 0;
		font-weight: 600; 
	}
	.btn-custom {
	  padding: 10px 20px;
	  font-size: 14px !important;
	}
	.btn-details-bg{
		margin: 15px -25px 0 -25px;
		padding: 12px 26px;
		border-top:1px solid #DFE3EC;
		display: block;
	}
}
.sec-get-started-bg{
	margin: 0;
	padding: 0;
	border-radius: 16px;
	border: 1px solid #DFE3EC;
	background: #F5F8FF;
	height: 100%;
	.get-started-head{
		padding: 20px 30px;
		border-radius: 16px 16px 0 0;
		background: url(../../images/get-started-head-bg.jpg) no-repeat top left;
		background-size: cover;
		color: $color-white;
		h3{
			margin: 15px 0 2px 0;
			color: $color-white;
			font-size: 20px;
		}
		a {
		  margin: 15px 0 0 0;
		  img{
			min-width: 30px;
		  }
		}
	}
	.get-started-content{
		padding: 20px 30px;
		h4{
			margin: 0 0 12px 0;
			text-transform: uppercase;
		}
	}
}

.giveFeedback{
	.modal-dialog {
	  max-width: 400px;
	}
	.modal-content{
		background:#ffffff url(../../images/feedback-popup-bg.svg) no-repeat left -15px top -15px;	
	} 
	.modal-body {
	  padding: 50px 24px 0 24px;
	}
	.ratings{
		margin: 20px 0 40px;
	}
	
}


