@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;800&display=swap');
body{
    margin:0;
    padding:0 !important;
    width:100%;
    color:$color-black-text;
    background:$color-white;
    font-size:14px;
    line-height: 22px;
	overflow-x: hidden;
	font-family: 'Inter', sans-serif !important;
	font-weight: 400;
}
html{
    overflow-x: hidden;
    scroll-behavior: smooth;
}
a {
    text-decoration:none;
    color: #005AFF;
}
a:hover, a:active{
    outline: 0;
    text-decoration:none;
    color: #021A53;
}
a:focus{
    outline: 0;
    text-decoration:none;
}

button:focus, button:active{
    outline: none;
    text-decoration:none;
}
:focus {
    outline: 0;
    box-shadow: none !important;
    border: 0;
}

ul {
    list-style: none;
}
img {
  max-width: 100%;
}
b, strong {
  font-weight: 600;
  color: $color-black;
}
input[type="text"], input[type="email"], input[type="search"], input[type="password"], textarea {
-webkit-appearance: none;
-moz-appearance: none; /* mobile firefox too! */
}
.form-check-input:focus {
  border: 1px solid #D0D5DD;
}
.form-select:focus {
  border: 1px solid #D0D5DD;
}

/*---Common Classes---*/
@media (min-width: 992px) {
  .container, .container-lg, .container-md, .container-sm {
    max-width: 98%;
  }
}
@media (min-width: 1350px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1320px;
  }
}
@media screen and (min-device-width: 320px) and (max-device-width: 1366px) { 
	.terms-condition-panel mat-dialog-container.mat-mdc-dialog-container .mat-mdc-dialog-surface .custom_modal .modal-content .modal_body{
		max-height: 90vh;
	}
	.terms-condition-panel mat-dialog-container.mat-mdc-dialog-container .mat-mdc-dialog-surface .custom_modal.card_confirm .modal-content .modal_body video {
		width: 100%;
		height: auto;
		max-height: 60vh;
	}
}
h1{
	color: $color-white;
	font-size: 32px;
	font-style: normal;
	font-weight: 600;
	line-height: 40px;
	font-family: 'Inter', sans-serif;
	@media(max-width:1300px){
		font-size: 26px;
		line-height: 34px;
	}
}
h2{
	color: $color-black;
	font-size: 24px;
	font-weight: 500;
	line-height: 30px;
	font-family: 'Inter', sans-serif;
	@media(max-width:768px){
		font-size: 19px;
	}
}
h3{
	margin-bottom: 5px;
	color: $color-black;
	font-size: 18px;
	font-weight: 600;
	line-height: 26px;
	font-family: 'Inter', sans-serif;
}
h4{
	margin: 0 0 5px 0;
	color: $color-black-text;
	font-size: 14px;
	font-weight: 600;
	line-height: 16px;
	font-family: 'Inter', sans-serif;
}
h5{
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	font-family: 'Inter', sans-serif;
}
h6{
	font-size: 12px;
	font-weight: 500;
	line-height: 16px;
	color: $color-black-text;
	font-family: 'Inter', sans-serif;
}

.btn-custom{
	margin: 0;
	padding: 10px 30px;
	background: #005AFF;
	color: $color-white !important;
	font-size: 16px  !important;
	font-weight: 600;
	line-height: 24px;
	display: inline-block;
	border-radius: 8px;
	border: 0;
	min-height: 45px;
	text-align: center;
	font-family: 'Inter', sans-serif;
	
	&:hover{
		background: #021A53 !important;
		color: $color-white !important;
	}
}
.btn-outline{
	background: $color-white;
	color: #414E6C !important;
	border:1px solid #9ba1af;
	font-family: 'Inter', sans-serif;
	
	&:hover{
		background: $color-blue;
		color: $color-white;
	}
}
.btn-arrow{
	padding: 0 27px 0 0;
	color: #005AFF;
	font-size: 14px;
	font-weight: 600;
	line-height: 18px;
	background: url(../../images/arrow-right-circle.svg) no-repeat 96% 0px;
	transition: all 0.2s ease-out;
	display: inline-block;
	font-family: 'Inter', sans-serif;
	&:hover{
		color: $color-blue;
		background: url(../../images/arrow-right-circle.svg) no-repeat 100% 0px;
	}
}

.action{
	img{
		width : "22px";
		height: "22px";
	}
}

.require-field{
	color: #ff6155;
    margin: 2px;
}

.bullet-list {
  margin: 0;
  padding-left: 15px;
  li {
	  margin: 0 0 3px 0;
	  list-style-type: disc;
	  font-family: 'Inter', sans-serif;
	}
}

.form-text {
	font-size: 13px;
	color: #ea4646;
	font-family: 'Inter', sans-serif;
}
.form-group{
	margin-bottom: 18px;
}
.form-label {
	font-size: 14px;
	font-weight: 500;
	color: #414E6C;
	font-family: 'Inter', sans-serif;
}
.form-control {
	font-size: 14px;
	font-weight: 400;
	height: 40px;
	font-family: 'Inter', sans-serif;
}

.form-control::placeholder {
  color: #A2ABC4;
}
.form-control:focus {
  border: 1px solid #005AFF;
  outline: 0;
  box-shadow: none;
}
input[type="password"]{
	font-size: 12px;
	font-family: 'Inter', sans-serif;
}
.form-select {
  background-position: right 8px center;
  color: var(--bs-body-color);
  font-size: 14px;
  min-height: 40px;
  font-family: 'Inter', sans-serif;
}
.badge {
  font-size: 10px;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
}  
.custom-tooltip {
  --bs-tooltip-bg: $color-blue;
  --bs-tooltip-color: $color-white;
}
.content-badge {
  margin: 0 0 8px 0;
  padding: 4px 10px;
  border: 1px solid var(--Primary-Blue-400, #B8D1FF);
	background: var(--Primary-Blue-200, #EBF1FF);
  color: #005AFF;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.6px;
  border-radius: 16px;
  display: inline-block;
  font-family: 'Inter', sans-serif;
}
.input-group{
	.form-control{
		// border-left:0;
	}
	.input-group-text {
	  background: transparent;
	  border-right: 0;
	  padding: 0 0 0 10px;
	}
}
.form-check-label{
    color: #081F2B;
	font-weight: 500;
	font-family: 'Inter', sans-serif;
}
textarea.form-control{
	height: 90px;
	resize: none;
}
.ratings {
	i{
		font-size: 50px;
		color:#F2F4F7;
		margin: 0 1%;
		font-style: normal;
	}
	.rating-color{
		color:#fdb022;
	}
}

/*---Navigation--*/
.offcanvas.offcanvas-start {
	top: 64px;
	background: #f5f8ff;
	z-index: 999;
	color: #667599;
	width: 365px;
	border-right: 1px solid #DFE3EC;
	visibility: visible;
	transform: none;
	
	@media(max-width:1500px){
		width: 240px;
	}
	.offcanvas-header{
		margin: 25px 0 5px 0;
		padding: 16px 22px;
		@media(max-width:1500px){
			padding: 15px;
			margin: 15px 0 0px 0;
		}
		.offcanvas-title{
			font-size: 18px;
			line-height: 24px;
			@media(max-width:1500px){
				font-size: 16px;
				font-family: 'Inter', sans-serif;
			}
		}
		.btn-close{
			margin-right: 5px;
			opacity: 1;
			background: url(../../images/icon-collapse-sidebar.svg) no-repeat left center;
			@media(max-width:1500px){
				margin-right: 0;
				padding-right: 0;
			}
			&:hover{
				opacity: 0.7;
			}
		}
	}
	a{
		margin: 0 0 25px 0;
		display: block;
		color: #667599;
		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
		font-family: 'Inter', sans-serif;
		@media(max-width:1500px){
			font-size: 15px;
			font-family: 'Inter', sans-serif;
		}
		img{
			margin: 0 10px 0 0;
			opacity: 0.6;
		}
		&:hover{
			color: $color-black;
			img{
				opacity: 1;
			}
		}
		
	}
	a.active{
		color: $color-black;
		img{
			opacity: 1;
		}
	}
	
	
	.bottom-link{
		padding: 25px 0 0 0;
		position: absolute;
		bottom: 18px;
		border-top: 1px solid #929497;
		left: 20px;
		right: 20px;
		font-family: 'Inter', sans-serif;
		@media(max-width:1500px){
			bottom: 5px;
		}
	}
	.offcanvas-body {
	  padding: 50px 15px 16px 35px;
		@media(max-width:1500px){
			padding: 40px 15px 16px 25px;
		}
	}
}
.offcanvas-backdrop{
	z-index: 997;
}
.offcanvas-backdrop.show {
  opacity: .3;
}

header{
	margin: 0;
	padding: 14px 40px 14px 25px;
	background: #021A53;
	z-index: 999;
	position: fixed;
	top: 0;
	width: 100%;
	@media(max-width:1500px){
		padding: 14px 15px;
	}
	.logo{
		margin-top: 8px;
	
	}
	.dropdown-menu.show{
		inset: 0px 0 auto auto !important;
	}
	.notification-bg{
		margin: 5px 22px 0 0;
		position: relative;
	}
}
.profile-bg{
	margin: 0;
	width: 36px;
	height: 36px;
	border-radius: 50%;
	&:hover{
		border: 1px solid $color-blue;
	}
}
.dropdown-toggle::after {
	display: none;
}
.header-title-sec{
	padding: 15px 40px;
	// padding: 32px 40px 5px 40px;
	// height: 120px;
	background: #021A53 url(../../images/header-bg.jpg) no-repeat center;
	background-size: cover;
	color: #E5EDFF;
	position: fixed;
    top: 64px;
    left: 0px;
	right: 0;
	width: auto;
	z-index: 998;
	font-size: 16px;
	margin-left: 0px;
	min-height: 108px;
	
	.sidebar-icon{
		margin: 3px 20px 0px 0;
		float: left;
	}
	h1{
		font-size: 32px;
		font-weight: 600;
		line-height: 30px;
		color: $color-white;
		font-family: 'Inter', sans-serif;
		@media(max-width:768px){
			font-size: 24px;
			line-height: 26px;
		}
	}
	h3{
		font-size: 20px;
		font-weight: 500;
		line-height: 20px;
		color: $color-white;
		font-family: 'Inter', sans-serif;
		@media(max-width:768px){
			font-size: 18px;
		}
	}
	.content-badge{
		margin-left: 20px;
        font-size: 12px;
        font-weight: 600;
		padding: 10px 20px;
		border-radius: 8px;
		color: $color-white;
        background: #005AFF;
		box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.10);
		display: flex;
        border: none;
        text-align: center;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}

    .content-badge:hover{
        color: white;
        background-color: #021A53;
    }
}

.wrapper-main{
	background: $color-white;
	// min-height: 100vh;
	overflow: hidden;
	h2{
		font-weight: 600;
		margin-bottom: 5px;
		font-family: 'Inter', sans-serif;
	}
}

.cp{
	cursor: pointer;
}

.btn-level{
	margin: 9px 0;
	padding: 4px 10px;
	background: $color-gray;
	color: $color-white;
	text-transform: uppercase;
	display: inline-block;
	font-size: 12px;
	font-weight: 500;
	line-height: 16px;
	letter-spacing: 0.6px;
	border-radius: 4px;
	min-width: 85px;
	text-align: center;
	font-family: 'Inter', sans-serif;
}
.level-medium{
	background: $color-orange;
}
.level-low{
	background: $color-red;
}
.level-high{
	background: $color-green;
}

.ag-header-cell-label{
	display: flex !important;
	justify-content: center !important;
	// font-size: 16px;
  }
// .ag-cell-value{
// 	font-size: 16px;
// }
.custom-is-invalid {
	border-color: #dc3545;
	/* Red border color */
}

.custom-is-invalid:focus {
	border-color: #dc3545;
	box-shadow: none;
	/* Remove the shadow */
}

.label-font{
	font-size:16px;
}

.ag-theme-quartz,
.ag-theme-quartz-dark {
    /* disable all borders */
    --ag-borders: none;
    /* then add back a border between rows */
    // --ag-row-border-style: dashed;
    // --ag-row-border-width: 2px;
    // --ag-row-border-color: rgb(150, 150, 200);
    /* and between columns */
    --ag-cell-horizontal-border: solid rgb(218, 218, 255);
}

.custome-table{
	td, th {
		color: #414E6C;
		padding: 11px 15px;
		vertical-align: middle;
	}
	th{
		font-size: 16px;
		font-weight: 500;
        color: #081F2B;
		background-color: #F6F7F9;
		text-align: left;
		white-space: nowrap;
	}
	td{
		strong {
		  font-weight: 500;
		  color: $color-black;
		  
		}
		a{
			opacity: 0.8;
			font-weight: 600;
			&:hover{
				color: $color-blue;
				opacity: 1;
			}
		  }
	}
	.content-badge {
		margin: 0;
		padding: 2px 8px;
		line-height: 19px;
	}	
	
	tr:nth-child(even)>td{
		background-color: #F6F7F9;	
	}	
	tr td:nth-of-type(8) {
	  text-align: center;
	}
    .pending, .submitted{
        color: #B54708;
        background: #FFFAEB;
        border: 1px solid #FEDF89;
      }
      
      .completed  {
        color: #067647;
        background: #ECFDF3;
        border: 1px solid #ABEFC6;
      }
      
      .in-progress {
        color: #FF3939;
        background: #FDECEC;
        border: 1px solid #faadad; 
      }
      
      .cancel {
        color: #1B1717;
        background: #B9AFAF;
        border: 1px solid #938787;
      }
      
      .output{
        color: #067647;
        background: #ECFDF3;
        border: 1px solid #ABEFC6;
      }
      
     
      .wui, .wup, .wip, .wpd, ro {
       color: #072260;
       background: #a8c2ff;
       border: 1px solid #7492da;
      }

      .succeeded{
        color: #067647;
        background: #ECFDF3;
        border: 1px solid #ABEFC6;
      }
	
}

table.roundedCorners { 
  border: 1px solid #eaecf0;
  border-radius: 16px; 
  border-spacing: 0;
  border-collapse: inherit;
  }
table.roundedCorners td, 
table.roundedCorners th { 
  border-bottom: 1px solid #eaecf0;
  font-family: 'Inter', sans-serif;
  }
table.roundedCorners tr:last-child > td {
  border-bottom: none;
}
table.roundedCorners thead th:first-child{
	border-radius: 16px 0 0 0;
}
table.roundedCorners thead th:last-child{
	border-radius: 0 16px 0 0;
}


.modal-footer{
	border: 0;
	justify-content: center;
	padding-bottom: 20px;
	.btn-custom {
	  margin: 0 1%;
	  padding: 8px 25px;
	  font-size: 15px !important;
	  font-weight: 500;
	  min-height: 30px;
	  width: 47%;
	  font-family: 'Inter', sans-serif;
	}
}
.modal-header{
	padding: 20px 24px;
}
.modal-body {
  padding: 20px 24px 20px 24px;
}
.form-check-input{
    color: #005AFF;
	border-color: rgba(0, 0, 0, 0.7294117647);
}

@media (min-width:320px) and (max-width:991px){
	.mat-sidenav-content.active .main-content-wrapper-body .header-title-sec,
	.mat-sidenav-content.active .main-content-wrapper-body footer{
		margin-left: 0px;
		height: auto;
	}
}

/*---Footer----*/

a{
	cursor: pointer;
	font-family: 'Inter', sans-serif;
}

footer{
	padding: 8px 40px;
	// padding: 32px 40px 5px 40px;
	// height: 120px;
	background: #021A53 url(../../images/header-bg.jpg) no-repeat center;
	background-size: cover;
	color: #E5EDFF;
	position: fixed;
    bottom: 0;
    left: 0;
	right: 0;
	width: auto;
	z-index: 998;
	font-size: 16px;
	margin-left: 0px;
	display: flex;
    justify-content: space-between;
    align-items: center;
	flex-wrap: wrap;
	gap: 10px;
}

.toast-container {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  position: fixed !important;
  width: auto !important;
  max-width: 100% !important;
  z-index: 9999;
}

.toast {
  white-space: nowrap; /* Ensure message is displayed in one line */
  overflow: hidden; /* Hide overflow text */
  text-overflow: ellipsis; /* Add ellipsis for long text */
}


@media screen and (max-width: 767px) {
	footer{
		justify-content: center;
	}
}
@media screen and (max-width: 991px) {
.terms-condition-panel{
	min-width: 95% !important;
	justify-content: center;
}
}

@media (min-width:320px) and (max-width:600px){
	.wrapper-main{
		padding: 100px 0px 100px 0px;
		// padding: 165px 0px 100px 0px;

	}
	.main-content-wrapper-body{
		padding: 30px 0px;
	}
	.header-title-sec{
		padding: 15px 0px;
		min-height: 88px;
	}
	header{
		padding: 15px 0px !important;
	}
	.header-title-sec .content-badge{
		margin-left: 0px;
	}
	.header-title-sec h1.mainline{
		font-size: 18px;
		line-height: 20px;
		margin-bottom: 8px;
	}
	.header-title-sec p.subline{
		font-size: 16px;
		line-height: 18px;
	}
	.welcome-subheader{
		// flex-direction: column !important;
		align-items: flex-start !important;
	}
	mat-dialog-container.mat-mdc-dialog-container .mat-mdc-dialog-surface .custom_modal .modal-content .modal_title{
		padding: 15px !important;
	}
	mat-dialog-container.mat-mdc-dialog-container .mat-mdc-dialog-surface .custom_modal .modal-content .modal_body{
		padding: 20px 15px !important;
	}
	mat-dialog-container.mat-mdc-dialog-container .mat-mdc-dialog-surface .custom_modal .modal-content .modal_footer{
		padding: 15px !important;
	}
	.tab-pane .new-create-task-section{
		padding: 100px 15px 60px 15px;
	}
	.sec-quote-compare-bg .download-xls-block{
		flex-direction: column;
		gap: 15px;
	}
	.new-task-form-block .your-tasks-bg .your-tasks-head .upload-file-block .ngx-file-drop__content .note-text .upload-file-link{
		font-size: 14px !important;
		text-align: center;
	}
	.your-tasks-bg .your-tasks-head .upload-file-block .ngx-file-drop__drop-zone,
	.your-tasks-bg .your-tasks-head .upload-file-block .ngx-file-drop__content{
		height: 80px;
		padding: 0px 5px;
	}
	.new-task-form-block .your-tasks-bg .your-tasks-head{
		padding: 15px !important;
	}
	.new-task-form-block{
		padding-top: 0px !important;
	}
	.footer{
		padding: 15px !important;
	}
	.copyright, .contact_us{
		font-size: 14px !important;
		line-height: 16px !important;
		text-align: center;
		justify-content: center;
	}
	.hambarger-btn{
		bottom: 88px;
	}
	.card-details-bg .form-check img{
		width: 38px !important;
	}
	.recent-activity .your-tasks-bg .your-tasks-filters .tasks-category-tab a{
		padding: 10px !important;
	}
	.login-bg .login-bg-left{
		margin-top: 0px !important;
		margin-bottom: 0px !important;
	}
	.login-bg .login-bg-left .policy-sec{
		max-width: 100% !important;
	}
	.wrapper-login.login-bg .align-items-stretch .login-bg-right .login-form{
		margin-bottom: 100px;
	}
	.verification-input .ng-otp-input-wrapper.wrapper .otp-input{
		width: 32px !important;
		height: 35px !important;
	}
	.wrapper-login.login-bg .login-bg-left h1{
		font-size: 16px;
		line-height: 22px;
	}
	.wrapper-login.login-bg .login-bg-right h1{
		font-size: 16px;
		line-height: 22px;
	}
	.wrapper-login.login-bg .login-bg-left .policy-sec h3{
		font-size: 14px;
	}
	.wrapper-login.login-bg .login-bg-left .policy-sec {
		font-size: 12px;
	}
	.wrapper-login.login-bg .login-bg-right h2{
		font-size: 14px;
		line-height: 22px;
	}
	.wrapper-login.login-bg .login-bg-right .btn-custom{
		font-size: 14px !important;
	}
}
@media (min-width:601px) and (max-width:767px){
	.wrapper-main{
		padding: 115px 0px 100px 0px;

	}
	.main-content-wrapper-body{
		padding: 30px 15px;
	}
	.header-title-sec{
		padding: 15px 15px;
	}
	header{
		padding: 15px 15px !important;
	}
	.header-title-sec .content-badge{
		margin-left: 0px;
	}
	mat-dialog-container.mat-mdc-dialog-container .mat-mdc-dialog-surface .custom_modal .modal-content .modal_title{
		padding: 15px !important;
	}
	mat-dialog-container.mat-mdc-dialog-container .mat-mdc-dialog-surface .custom_modal .modal-content .modal_body{
		padding: 20px 15px !important;
	}
	mat-dialog-container.mat-mdc-dialog-container .mat-mdc-dialog-surface .custom_modal .modal-content .modal_footer{
		padding: 15px !important;
	}
	.tab-pane .new-create-task-section{
		padding: 100px 15px 60px 15px;
	}
	.hambarger-btn{
		bottom: 66px;
	}
	.login-bg .login-bg-left .policy-sec{
		max-width: 100% !important;
	}
	.wrapper-login.login-bg{
		height: 100% !important;
	}
	.login-bg .login-bg-left{
		margin-top: 0px !important;
		margin-bottom: 0px !important;
	}
	.wrapper-login.login-bg .align-items-stretch .login-bg-right .login-form{
		margin-bottom: 80px;
	}
	.wrapper-login.login-bg .login-bg-left h1{
		font-size: 26px;
		line-height: 34px;
	}
	.wrapper-login.login-bg .login-bg-right h1{
		font-size: 26px;
		line-height: 34px;
	}
	.wrapper-login.login-bg .login-bg-left .policy-sec h3{
		font-size: 14px;
	}
	.wrapper-login.login-bg .login-bg-left .policy-sec {
		font-size: 12px;
	}
	.wrapper-login.login-bg .login-bg-right h2{
		font-size: 19px;
		line-height: 22px;
	}
	.wrapper-login.login-bg .login-bg-right .btn-custom{
		font-size: 16px !important;
	}
}
@media (min-width:768px) and (max-width:991px){
	.wrapper-main{
		padding: 115px 0px 100px 0px;

	}
	.main-content-wrapper-body{
		padding: 30px 15px;
	}
	.header-title-sec{
		padding: 15px 15px;
	}
	header{
		padding: 15px 15px !important;
	}
	.header-title-sec .content-badge{
		margin-left: 0px;
	}
	.tab-pane .new-create-task-section{
		padding-bottom: 30px;
	}
	.new-create-task-section .new-task-form-block{
		padding: 15px 15px;
	}
	.mat-drawer-content .main-content-wrapper-body .tab-pane{
		padding-top: 100px;
	}
	.mat-drawer-content .main-content-wrapper-body .wrapper-main.profile-settings .tab-pane{
		padding-top: 0px;
	}
	.hambarger-btn{
		bottom: 66px;
	}
	.wrapper-login.login-bg{
		height: 100% !important;
		margin-bottom: 60px;
	}
	.wrapper-login.login-bg.email-varification-section{
		height: 100% !important;
		margin-bottom: 60px;
	}
	.wrapper-login.login-bg .align-items-stretch .login-bg-right .login-form{
		margin-bottom: 100px;
	}
	.wrapper-login.login-bg .login-bg-left h1{
		font-size: 14px;
		line-height: 20px;
	}
	.wrapper-login.login-bg .login-bg-left p{
		font-size: 12px;
		line-height: 22px;
	}
	.wrapper-login.login-bg .login-bg-right h1{
		font-size: 20px;
		line-height: 22px;
	}
	.wrapper-login.login-bg .login-bg-right h2{
		font-size: 18px;
		line-height: 22px;
	}
	.wrapper-login.login-bg .login-bg-right .btn-custom{
		font-size: 14px !important;
	}
}
@media (min-width:992px) and (max-width:1023px){
	.header-title-sec{
		padding: 15px 15px;
	}
	.main-content-wrapper-body{
		padding: 15px 15px 100px 15px;
	}
	.new-create-task-section .new-task-form-block{
		padding: 15px 15px;
	}
	.mat-drawer-content .main-content-wrapper-body .tab-pane{
		// padding-top: 100px;
	}
	.hambarger-btn{
		bottom: 66px;
	}
	// .wrapper-login.login-bg{
	// 	height: 100% !important;
	// 	margin-bottom: 30px;
	// }
	.wrapper-login.login-bg{
		height: 100% !important;
		margin-bottom: 60px;
	}
	.wrapper-login.login-bg .login-bg-left h1{
		font-size: 18px;
		line-height: 24px;
	}
	.wrapper-login.login-bg .login-bg-left p{
		font-size: 14px;
		line-height: 22px;
	}
	.wrapper-login.login-bg .login-bg-right h1{
		font-size: 18px;
		line-height: 24px;
	}
	.wrapper-login.login-bg .login-bg-right h1{
		font-size: 20px;
		line-height: 22px;
	}
	.wrapper-login.login-bg .login-bg-right h2{
		font-size: 18px;
		line-height: 22px;
	}
	.wrapper-login.login-bg .login-bg-right .btn-custom{
		font-size: 14px !important;
	}

}
@media (min-width:1024px) and (max-width:1199px){
	.header-title-sec{
		padding: 15px 15px;
	}
	.main-content-wrapper-body{
		padding: 25px 15px 100px 15px;
	}
	.new-create-task-section .new-task-form-block{
		padding: 15px 15px;
	}
	.wrapper-login.login-bg{
		height: 100% !important;
		margin-bottom: 30px;
	}
	// .mat-drawer-content .main-content-wrapper-body .tab-pane{
	// 	padding-top: 100px;
	// }

	.wrapper-login.login-bg .login-bg-left h1{
		font-size: 20px;
		line-height: 26px;
	}
	.wrapper-login.login-bg .login-bg-left p{
		font-size: 14px;
		line-height: 22px;
	}


}
@media (min-width:992px) {
	.terms-condition-panel{
		.mat-mdc-dialog-container{
			width: 750px;
		}
	}
	}
@media (min-width:1100px) {
.terms-condition-panel{
	.mat-mdc-dialog-container{
		width: 1050px;
	}
}
}
@media (min-width:1200px) {
	.header-title-sec{
		padding: 15px 15px;
	}
	.main-content-wrapper-body{
		padding: 30px 15px 100px 15px;
	}
	.new-create-task-section .new-task-form-block{
		padding: 15px 15px;
	}
	.login-bg .login-bg-left{
		margin-bottom: 80px !important;
	}

	// .wrapper-login.login-bg{
	// 	height: auto !important;
	// 	margin-bottom: 60px;
	// }
	// .mat-drawer-content .main-content-wrapper-body .tab-pane{
	// 	padding-top: 100px;
	// }
}
@media (min-width:1200px) and (max-width:1439px){
	.wrapper-login.login-bg .login-bg-left h1{
		font-size: 24px;
		line-height: 30px;
	}
	.wrapper-login.login-bg .login-bg-left p{
		font-size: 14px;
		line-height: 22px;
	}
}
@media (min-width:1440px) and (max-width:1600px){
	.wrapper-login.login-bg .login-bg-left h1{
		font-size: 26px;
		line-height: 34px;
	}
	.wrapper-login.login-bg .login-bg-left p{
		font-size: 14px;
		line-height: 22px;
	}
}
@media (min-width:1601px) and (max-width:1800px){
	.wrapper-login.login-bg .login-bg-left h1{
		font-size: 24px;
		line-height: 30px;
	}
	.wrapper-login.login-bg .login-bg-left p{
		font-size: 14px;
		line-height: 22px;
	}
}